// src/main.js

// 导入 jQuery
import $ from 'jquery';
window.jQuery = window.$ = $;

// 导入 Popper.js (Bootstrap 依赖)
import '@popperjs/core';

// 导入完整的 Bootstrap
import 'bootstrap';

// 导入 Feather icons
import 'feather-icons';

// 初始化 Feather icons
document.addEventListener('DOMContentLoaded', () => {
  feather.replace();
});

// 在这里添加您的自定义 JavaScript 代码
// 例如: 语言切换功能
function setLanguage(lang) {
  // 实现语言切换逻辑
  console.log(`Language set to: ${lang}`);
}

// 暴露全局函数
window.setLanguage = setLanguage;

// 如果您有任何其他自定义的 JavaScript 代码，可以在这里添加
// 例如，如果您有特定的 jQuery 代码，可以这样添加：
$(document).ready(function() {
  // 您的 jQuery 代码
});